<template>
  <b-container fluid>
    <bo-page-title />
    <section id="career">
      <b-tabs v-if="isList">
        <b-tab title="Page Settings">
          <HeroSection :modulePage="modulePage" v-if="moduleRole('hero')" />

          <b-card no-body v-if="moduleRole('vacancies')">
            <b-card-header>
              <b-row class="justify-content-between">
                <b-col md=3>
                  <b-card-title title-tag="h5">Job Vacancies</b-card-title>
                </b-col>
                <b-col md=3 class="text-right">
                  <b-button variant="success" class="m-l-15 btn-rounded" :to="{ name: routeName, params: { pageSlug: 'add' } }">
                    <i class="fa fa-plus-circle mr-2" />
                    Add Job Vacancies
                  </b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row class="gutter-2" v-if="(data.data||[]).length">
                <b-col md="4" v-for="(career, key) in (data.data||[])" :key="key"
                >
                  <div class="main-card main-card--sm career-card">
                    <div class="main-card-header">
                      <h3 class="career-card__title">
                        <template v-if="career.ac_type == 'fulltime'">Full Time</template>
                        <template v-else-if="career.ac_type == 'parttime'">Part Time</template>
                        <template v-else-if="career.ac_type == 'internship'">Intership</template>
                      </h3>
                    </div>
                    <div class="main-card-body">
                      <div class="career-reqs">
                        <b-row class="gutter-1">
                          <b-col md="12">
                            <b-badge :variant="career.ac_is_active == 'N' ? 'danger' : 'success'">
                            {{career.ac_is_active == 'Y' ? 'Active' : 'Inactive'}}
                            </b-badge>
                          </b-col>
                          <b-col md="12">
                            <div class="career-req__item">
                              <i class="icon-location-pin"></i>
                              <span>{{career.ac_location}}</span>
                            </div>
                          </b-col>
                          <b-col md="12">
                            <div class="career-req__item">
                              <i class="icon-bag"></i>
                              <span>{{career.ac_position_id}}</span>
                            </div>
                          </b-col>
                          <b-col md="12">
                            <div class="career-req__item">
                              <i class="icon-clock"></i>
                              <span>{{career.ac_start_date}} s.d. {{ career.ac_end_date }}</span>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="main-card-footer">
                      <div class="d-flex">
                        <div class="ml-auto">
                          <b-button
                            class="btn-icon"
                            variant="outline-warning"
                            v-if="moduleRole('edit')"
                            :to="{ name: routeName, params: { pageSlug: career.ac_id } }"
                            v-b-tooltip.hover="'Update Vacancy'"
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </b-button>
                          
                          <b-button
                            class="btn-icon ml-1"
                            variant="outline-info"
                            @click="doChangeStatus(key,career)"
                            v-if="moduleRole('status')"
                            v-b-tooltip.hover="'Update Vacancy Status'"
                          >
                            <i class="fas fa-cog"></i>
                          </b-button>

                          <b-button
                            class="btn-icon ml-1"
                            variant="outline-danger"
                            @click="doDelete(key,career)"
                            v-if="moduleRole('delete')"
                            v-b-tooltip.hover="'Delete Vacancy'"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-else>
                <div class="text-center m-auto">
                  <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                  <h4 align="center">There is no data</h4>
                </div>
              </b-row>
            </b-card-body>
            <template #footer>
              <b-pagination
                  v-if="data.total > data.per_page"
									class="mb-0 justify-content-end"
									v-model="pageNo"
									:per-page="data.per_page"
									:total-rows="data.total"
								/>
            </template>
          </b-card>
        </b-tab>
        
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
      <template v-else>
        <validation-observer ref="VForm" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitCareer)">
            <b-card no-body>
              <template #header>
                <h5 class="card-title">Job Vacancy Information</h5>
              </template>
              <b-card-body>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <b-col md="4">
                        <b-form-group label-for="jobType">
                          <template #label>Job Type<strong class="text-danger">*</strong></template>
                          <v-select id="jobType" :reduce="el => el.value" v-model="row.ac_type" :options="mrJobType" />
                          <VValidate 
                            name="Job Type" 
                            v-model="row.ac_type" 
                            :rules="mrValidation.ac_type" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label-for="location">
                          <template #label>Location <strong class="text-danger">*</strong></template>
                          <b-form-input placeholder="e.g. Karawang, Jawa Barat" id="location" v-model="row.ac_location" @keyup="removeWildChar" @keydown="removeWildChar"/>
                          <VValidate 
                            name="Location" 
                            v-model="row.ac_location" 
                            :rules="{...mrValidation.ac_location, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <div class="w-100"></div>
                      <b-col lg="3" class="mb-2">
                        <b-form-group label-for="startDate">
                          <template #label>Start Date<span class="text-danger mr5">*</span></template>
                          <div class="input-group">
                            <datepicker @input="changeStartDate" input-class="form-control transparent" v-model="row.ac_start_date" placeholder="Select Start Date"></datepicker>
                            <div class="input-group-append calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                            </div>
                          </div>

                          <VValidate 
                            name="Start Date" 
                            v-model="row.ac_start_date" 
                            :rules="mrValidation.ac_end_date"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" class="mb-2">
                        <b-form-group label-for="startDate">
                          <template #label>End Date<span class="text-danger mr5">*</span></template>
                          <div class="input-group">
                            <datepicker input-class="form-control transparent" v-model="row.ac_end_date" :disabled-dates="{to: new Date(row.ac_start_date)}" placeholder="Select Start Date"></datepicker>
                            <div class="input-group-append calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                            </div>
                          </div>

                          <VValidate 
                            name="End Date" 
                            v-model="row.ac_end_date" 
                            :rules="mrValidation.ac_end_date"
                          />
                        </b-form-group>
                      </b-col>
                      <div class="w-100"></div>
                      <b-col md="4">
                        <b-form-group label-for="jobPosition">
                          <template #label>Position (ID)<strong class="text-danger">*</strong></template>
                          <b-form-input placeholder="e.g. Operator" id="jobPosition" v-model="row.ac_position_id" @keyup="removeWildChar" @keydown="removeWildChar"/>
                          <VValidate 
                            name="Position (ID)" 
                            v-model="row.ac_position_id" 
                            :rules="{...mrValidation.ac_position_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobPositionEN">
                          <template #label>Position (EN)<strong class="text-danger">*</strong></template>
                          <b-form-input placeholder="e.g. Operator" id="jobPositionEN" v-model="row.ac_position_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                          <VValidate 
                            name="Position (EN)" 
                            v-model="row.ac_position_en" 
                            :rules="{...mrValidation.ac_position_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobPositionJP">
                          <template #label>Position (JP)<strong class="text-danger">*</strong></template>
                          <b-form-input placeholder="e.g. オペレーター" id="jobPositionJP" v-model="row.ac_position_jp" @keyup="removeWildChar" @keydown="removeWildChar"/>
                          <VValidate 
                            name="Position (JP)" 
                            v-model="row.ac_position_jp" 
                            :rules="{...mrValidation.ac_position_jp, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <div class="w-100"></div>
                      <b-col md="4">
                        <b-form-group label-for="jobDescriptionId">
                          <template #label>Job Description (ID)<strong class="text-danger">*</strong></template>
                          <CKEditor 
                            id="jobDescriptionId"
                            :value.sync="row.ac_content_id"
                          />
                          <VValidate 
                            name="Job Description (ID)" 
                            v-model="row.ac_content_id" 
                            :rules="mrValidation.ac_content_id" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobDescriptionEn">
                          <template #label>Job Description (EN)<strong class="text-danger">*</strong></template>
                          <CKEditor 
                            id="jobDescriptionEn"
                            :value.sync="row.ac_content_en"
                          />
                          <VValidate 
                            name="Job Description (EN)" 
                            v-model="row.ac_content_en" 
                            :rules="mrValidation.ac_content_en" 
                          />
                        
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobDescriptionEn">
                          <template #label>Job Description (JP)<strong class="text-danger">*</strong></template>
                          <CKEditor 
                            id="jobDescriptionEn"
                            :value.sync="row.ac_content_jp"
                          />
                          <VValidate 
                            name="Job Description (JP)" 
                            v-model="row.ac_content_jp" 
                            :rules="mrValidation.ac_content_jp" 
                          />
                        
                        </b-form-group>
                      </b-col>
                      <div class="w-100"></div>
                      <b-col lg="6">
                        <b-form-group class="mt-3">
                          <label>Status<span class="text-danger mr5">*</span></label>
                          <b-form-radio-group
                            :options="Config.mr.StatusOptions"
                            v-model="row.ac_is_active"
                          />
                          <VValidate 
                            name="Status" 
                            v-model="row.ac_is_active" 
                            :rules="mrValidation.ac_is_active" 
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
              <template #footer>
                <div class="text-right">
                  <b-button type="button" variant="secondary" @click="() => $router.push({name: $route.name})" class="btn-rounded">Cancel</b-button>
                  <b-button type="submit" variant="info" class="ml-3 btn-rounded">Save Changes</b-button>
                </div>
              </template>
            </b-card>
          </b-form>
        </validation-observer>
      </template>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import CKEditor from '@/components/CKEditor'
import Datepicker from 'vuejs-datepicker'
import SEOSection from '@/components/SEOSection'
import HeroSection from '@/components/HeroSection'

let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  components: {
    CKEditor,
    Datepicker,
    SEOSection,
    HeroSection
  },
  data() {
    return {
      currentTitle: '',
      heroEditorOpen: false,
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
      data: [],
      companyDesc: {},
      idKey:'ac_id',    
      statusKey: 'ac_is_active',
      mrJobType: [],
      mrValidation: {},
      tabIndex : 0,
    }
  },
  methods: {
    changeStartDate(date){
      if(this.row.ac_end_date && this.$moment(date).format('YYYY-MM-DD') > this.$moment(this.row.ac_end_date).format('YYYY-MM-DD')){
        this.row.ac_end_date = null
      }
    },
    changeHero(){
      this.heroEditorOpen = !this.heroEditorOpen
    },
    autoFill(to = "", to2 = "", from = ""){
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]    
    },
    submitCompanyDesc(IdFormContent = 'VFormContent'){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateCompanyDesc'
        }, this.companyDesc),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true  
          
            this.apiGet()
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IdFormContent
      )
    },
    
    submitCareer(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) return        
        this.doSubmitCRUD('VForm', this.$refs)
      })
    },
  },
  mounted() {
    this.apiGet()    
  },
  watch:{
    $route(){
      this.apiGet()  
    },
  }
}
</script>