<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title title-tag="h5">Hero Image</b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center">
        <b-col lg="3" md="4" class="slider-item">
          <bo-card-img :title="(slider.ahi_title_id || '')" :src="parent.uploader(slider.ahi_image, '/assets/images/no-slide.jpg')">
            <template #buttons>
              <b-button
                variant="secondary"
                size="sm"
                pill
                @click="updateSlide"
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Update'"
              >
                <i class="fas fa-pencil-alt" />
              </b-button>
            </template>
          </bo-card-img>
        </b-col>
      </div>

      <b-modal id="modalHero" size="xl" title="Update Hero Image" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormSlider"
        >
          <b-form @submit.prevent="handleSubmit(submitSlider)">
            <b-card-body>
              <b-row>
                <b-col md=4>
                  <div class="form-group mb-2">
                    <label class="control-label">Title (ID) <span class="text-danger">*</span></label>
                    <input type="text"
                      @keyup="parent.removeWildChar"
                      @keydown="parent.removeWildChar"
                      class="form-control"
                      placeholder="Title"
                      v-model="slideRow.ahi_title_id">
                    <VValidate 
                        name="Title (ID)" 
                        v-model="slideRow.ahi_title_id"
                        :rules="{...mrValidation.ahi_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                  </div>
                  <div class="form-group mb-2">
                    <label class="control-label">Description (ID)</label>
                    <b-form-textarea :rows="3" v-model="slideRow.ahi_description_id"></b-form-textarea>
                    <VValidate 
                        name="Description (ID)" 
                        v-model="slideRow.ahi_description_id"
                        :rules="mrValidation.ahi_description_id" 
                      />
                  </div>
                </b-col>
                <b-col md=4>
                  <div class="form-group mb-2">
                    <label class="control-label">Title (EN) <span class="text-danger">*</span></label>
                    <input type="text"
                      @keyup="parent.removeWildChar"
                      @keydown="parent.removeWildChar"
                      class="form-control"
                      placeholder="Title"
                      v-model="slideRow.ahi_title_en">
                    <VValidate 
                        name="Title (EN)" 
                        v-model="slideRow.ahi_title_en"
                        :rules="{...mrValidation.ahi_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                  </div>
                  <div class="form-group mb-2">
                    <label class="control-label">Description (EN)</label>
                    <b-form-textarea :rows="3" v-model="slideRow.ahi_description_en"></b-form-textarea>
                    <VValidate 
                        name="Description (EN)" 
                        v-model="slideRow.ahi_description_en"
                        :rules="mrValidation.ahi_description_en" 
                      />
                  </div>
                </b-col>
                <b-col md=4>
                  <div class="form-group mb-2">
                    <label class="control-label">Title (JP) <span class="text-danger">*</span></label>
                    <input type="text"
                      @keyup="parent.removeWildChar"
                      @keydown="parent.removeWildChar"
                      class="form-control"
                      placeholder="Title"
                      v-model="slideRow.ahi_title_jp">
                    <VValidate 
                        name="Title (JP)" 
                        v-model="slideRow.ahi_title_jp"
                        :rules="mrValidation.ahi_title_jp" 
                      />
                  </div>
                  <div class="form-group mb-2">
                    <label class="control-label">Description (JP)</label>
                    <b-form-textarea :rows="3" v-model="slideRow.ahi_description_jp"></b-form-textarea>
                    <VValidate 
                        name="Description (JP)" 
                        v-model="slideRow.ahi_description_jp"
                        :rules="mrValidation.ahi_description_jp" 
                      />
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="form-group">
                    <label class="control-label">Hero Image <span class="text-danger">*</span></label>         
                    <Uploader v-model="slideRow.ahi_image" type="slider"/>
                    <VValidate 
                      name="Hero Image" 
                      v-model="slideRow.ahi_image"
                      :rules="mrValidation.ahi_image" 
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="submitSlider" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
    </b-card-body>
    <b-card-footer>

    </b-card-footer>
  </b-card>
</template>
<script>
import BoCardImg from '@/components/backend/BoCardImg.vue'
import Gen from '@/libs/Gen.js'

let _ = global._

export default {
  props: {
    modulePage: String,
  },
  components: {
    BoCardImg
  },
  data(){
    return {
      sliderData: [],
      sliderCollapse: false,
      mrValidation: {},
      slider: {},
      slideRow: {}
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    submitSlider(){
      this.$refs.VFormSlider.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateHero', ...this.slideRow } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$bvModal.hide('modalHero')
                this.apiGet()
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    updateSlide(){
      this.slideRow = _.clone(this.slider)
      this.$bvModal.show('modalHero')
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/hero', {}, 'GET').then(res => {
        this.slider = res.data.slider
        this.mrValidation = res.data.validation
      })
    }
  }
}
</script>
