<template>
  <div class="card-img">
    <template v-if="!isVideo">
      <b-img
        fluid
        class="card-img__media"
        :alt="title"
        :src="src"
        :blank="!src"
        blank-color="#ccc"
        width="640"
        height="360"
      />
    </template>
    <template v-else>
      <video :src="src" type="video/mp4" controls></video>
    </template>
    <span class="card-img__title">{{ title }}</span>
    <div class="bullet-cta-left">
      <slot name="info" />
    </div>
    <div class="bullet-cta">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    isVideo: {type: Boolean, default: () => (false)}
  }
}
</script>
<style scoped>
.card-img video{
  width: 100%;
}
</style>
